import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import {Auth} from "aws-amplify"
import { adminRoot, currentUser } from "../../constants/defaultValues"
import { setCurrentUser } from '../../helpers/Utils';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);
const signIn = async (email,password) => {
     
  
      try {
        const user = await Auth.signIn(email, password);
        console.log(user)
        
      } catch (err) {
        switch (err.name) {
          case 'UserNotFoundException':
            break;
          case 'NotAuthorizedException':
            break;
          case 'UserNotConfirmedException':
            break;
          case 'AuthError':
            break;
          case 'InvalidParameterException':
            break;
          default:
            console.log("default")
        }
      }
    };
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    //const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    const user = yield Auth.signIn(email, password);
        console.log(user)
    /**date: "Last seen today 15:24"
id: 1
img: "/assets/img/profiles/l-1.jpg"
role: 0
title: "Sarah Kortney"
uid: "bzebm5ZQnhepuRBYAAZBbWxa1lm2 */
    if (user.challengeName=="NEW_PASSWORD_REQUIRED") {
      yield put(loginUserError("New password Requried"));
      history.push({
        pathname: "/user/change-password",
        state: { email: email,password:password }
      })
      /* const item =  {id: 1,
        img: "/assets/img/profiles/l-1.jpg",
        role: 0,
        title: "Shabazz Graham",
        uid: "bzebm5ZQnhepuRBYAAZBbWxa1lm2" };  */
    } else {
      console.log(user)
      setCurrentUser(user);
      yield put(loginUserSuccess(user));
      history.push(adminRoot);
    }
  } catch (err) {
    console.log(err)
    console.log(err?.message)
    switch (err.name) {
      case 'UserNotFoundException':
        yield put(loginUserError("User not found"));
        break;
      case 'NotAuthorizedException':
        yield put(loginUserError("Invalid Credential"));
        break;
      case 'UserNotConfirmedException':
        yield put(loginUserError("User not confirmed"));
        break;
      case 'AuthError':
        yield put(loginUserError("Invalid Credential"));
        break;
      case 'InvalidParameterException':
        yield put(loginUserError("Invalid Parameter"));
        break;
      default:
        yield put(loginUserError("Network Error"));
    }
    
  }
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  await auth
    .signOut()
    .then((user) => user)
    .catch((error) => error);
  history.push(adminRoot);
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await auth
    .sendPasswordResetEmail(email)
    .then((user) => user)
    .catch((error) => error);
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
